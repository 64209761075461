import { Modal, Result } from "antd-mobile";
import { useEffect, useMemo, useState } from "react";
import { uploadPossiblePayment } from "services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "services-controller/storage";
import { useSelector } from "react-redux";
import PayModal from "./PayModal";
import { message } from "antd";

const PayModalController = ({ data, actions, states, selectedProject }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [files, setFiles] = useState([]);
  const [isSendedToPayRevision, setIsSendedToPayRevision] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const auth = useSelector((state) => state.auth);
  const currentServiceId = useMemo(() => selectedProject?.unique_key, [selectedProject]);

  useEffect(() => {
    setTimeout(() => {
      setIsSendedToPayRevision(false);
    }, 1000);
  }, [isSendedToPayRevision]);

  useEffect(() => {

    if (data) {
      setLoading(false);

      const open = data?.some((service) => {

        const openPaymentReminder = service.wallet.is_to_paid && service.wallet?.invoicesToRevision?.length >= service.wallet?.invoices?.length;

        return openPaymentReminder;
      });

      actions?.setOpenPayRequiredModal(open);
    }
  }, [data]); //eslint-disable-line

  const onUploadInvoice = async () => {
    try {
      if (!currentServiceId) {
        messageApi.warning("No se ha seleccionado un proyecto, inténtalo mas tarde", 4);
        return;
      }
      if (!files.length) {
        messageApi.error("Debes cargar un archivo");
        return;
      }
      if (files.some(file => !file.type)) {
        messageApi.error("Tipo de archivo no soportado");
        return;
      }
      const uploadedFiles = await uploadMultipleEvidenceResources(files);

      //solo se le permite enviar un archivo
      const invoice = {
        ...uploadedFiles.at(0),
        uploaded_date: new Date(),
      };
      await uploadPossiblePayment(currentServiceId, invoice);
      setIsSendedToPayRevision(true);
      actions?.setOpenPayModal(false);
      actions?.setOpenPayRequiredModal(false);
      setFiles([]);

    } catch (err) {
      console.log(err);

      messageApi.error("Ocurrió un error al subir el archivo");
    }

  };

  const onDeleteFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const onConfirm = (flag, id) => {
    actions?.setOpenPayModal(flag === "pay_ready");
    setId(id);
    actions?.setOpenPayRequiredModal(false);
  };

  let state = {
    files,
    setFiles,
    uploadInvoiceModal: states?.openPayModal,
    setUploadInvoiceModal: actions?.setOpenPayModal,
  };

  let functions = {
    onUploadInvoice,
    onDeleteFile,
    onConfirm,
  };

  const style = {
    backgroundColor: "#652d52",
    color: "white",
    padding: "10px 20px",
  };

  return (
    <>
      {isSendedToPayRevision ? (
        <Modal
          visible={isSendedToPayRevision}
          content={
            <Result
              status="success"
              description="El pago se envio a revisión"
            />
          }
        />
      ) : (
        <PayModal
          open={states?.openPayRequiredModal}
          data={data}
          loading={loading}
          functions={functions}
          style={style}
          state={state}
          user={auth.user}
        />
      )}
      {contextHolder}
    </>
  );
};

export default PayModalController;
