import React from "react";
import { notification } from "antd";
import useReduxState from "custom-hooks/useReduxState";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  changeGeneralState,
  setServiceBudget,
  setServiceData,
  setServiceOperation,
} from "reducers/new.advance.detail";
import {
  getBudgetById,
  getQuotationById,
  getServiceById,
  getServiceOperationById,
} from "services-controller/http.cleinte.controller";
import NewAdvanceForm from "views/NewAdvanceForm/NewAdvanceForm";
import "./NewAdvanceDetail.css";
import ActivitiesList from "./components/ActivitiesList";
import HeaderSection from "./components/HeaderSection";
import DocsPanel from "./components/DocsPanel";
import NavBarController from "components/Navbar/NavBarController";

export default function NewAdvanceDetail() {
  const { id } = useParams();
  const [, dispatch] = useReduxState(
    {
      changeGeneralState,
      setServiceOperation,
      setServiceData,
      setServiceBudget,
    },
    "new_advance_detail"
  );
  const history = useHistory();
  const [service, setService] = React.useState(null);

  const setDataForService = async (id) => {
    const service = await getServiceById(id);
    const quotation = await getQuotationById(service.quotation_id);

    return {
      service: service,
      quotation: quotation,
    };
  };

  const getData = async () => {
    try {
      dispatch.changeGeneralState({ isLoading: true });

      const serviceOperationResponse = await getServiceOperationById(id);
      dispatch.setServiceOperation(serviceOperationResponse);

      const serviceResponse = await getServiceById(id);
      dispatch.setServiceData(serviceResponse);

      if (
        serviceResponse?.version !== 2 ||
        !serviceOperationResponse.workplan
      ) {
        notification.config({ top: 80 });
        notification.open({
          message: (
            <h1
              style={{
                margin: "0px",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "normal",
              }}
            >
              Solo puedes ver la bitácora
            </h1>
          ),
          description:
            "El servicio seleccionado no pertenece a la nueva versión o no tiene plan de trabajo",
          type: "warning",
          duration: 5,
          placement: "topLeft",
        });
        history.push("/all-services-added");

        if (serviceResponse?.bitacle_v === 2) {
          history.push("/chat-phases/" + id);
        } else {
          history.push("/services/bitacle/" + id);
        }
        return;
      }

      const data = await setDataForService(id);
      setService(data);

      const budgetResponse = await getBudgetById(id);
      dispatch.setServiceBudget(budgetResponse);

      dispatch.changeGeneralState({ isSuccess: true, isLoading: false });
    } catch (error) {
      dispatch.changeGeneralState({ isError: true, isLoading: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  return (
    <main className="new-advance-detail">
      <HeaderSection />
      <ActivitiesList />
      <NavBarController isInWorkerPlan={true} />
      <NewAdvanceForm refreshData={getData} />
      <DocsPanel obj={service} />
    </main>
  );
}
