// import { MoreOutlined } from "@ant-design/icons";
import { message as ScreenMessages, Spin } from "antd";

import { useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "./ChatBody.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useCallback } from "react";
import {
  getInitialMessagesV2,
  getNewMessages,
} from "../../../../../../services-controller/database";

import Message from "./Message";
import { DownCircleFilled } from "@ant-design/icons";
import { useMemo } from "react";
import { put } from "services/handler.request";
import { format, isToday, isYesterday, isThisWeek } from "date-fns";
import { es } from "date-fns/locale";

const updateMessage = async (id, entryId, message) => {
  try {
    const res = await put(`bitacle/message/${id}/${entryId}`, {
      message,
    });

    return res;
  } catch (err) {
    console.log("Error " + err.message);
    return err;
  }
};

// const typesMsgCanBeEdited = ["message", "activity"]

const formatDate = (date) => {  
  const messageDate = new Date(date);
  if (isToday(messageDate)) {
    return "Hoy";
  } else if (isYesterday(messageDate)) {
    return "Ayer";
  } else if (isThisWeek(messageDate)) {
    return format(messageDate, "EEEE", { locale: es }); // Day of the week in Spanish
  } else {
    return format(messageDate, "dd/MM/yyyy", { locale: es }); // Date format in Spanish
  }
};

export default function ChatBody() {
  // const chat = useSelector((state) => state.bitacle.chat, shallowEqual)
  const [chat, setChat] = useState([]);  
  const [countDeleted, setCountDeleted] = useState(0);
  const [countApproved, setCountApproved] = useState(0);  
  const chatBodyRef = useRef(null);
  const chatPhaseSelected = useSelector(
    (state) => state.bitacle.chatPhaseSelected,
    shallowEqual
  );
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const userId = user.id;
  const [isLoading, setIsLoading] = useState(false);
  const { fase, id: serviceId } = useParams();

  const newMessage = (message) => setChat((prev) => [...prev, message]);

  const [openOptions, setOpenOptions] = useState(false);

  const scrollToPosition = useCallback(
    (position) => {
      const element = chatBodyRef.current?.querySelector(
        `[data-key="${position}"]`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    [chatBodyRef]
  );

  useEffect(() => {
    chatBodyRef.current?.scrollTo(0, chatBodyRef.current?.scrollHeight);
  }, [chat, chatPhaseSelected]);

  const mappingMessages = useCallback(
    (messages) =>
      Array.isArray(messages)
        ? messages.map((item) => ({
            ...item,
            isOtherUser: item.emisor?.id !== userId,
          }))
        : { ...messages, isOtherUser: messages.emisor?.id !== userId },
    [userId]
  );

  const loadMoreMessages = useCallback(async () => {
    try {
      setIsLoading(true); // Marca el inicio de la carga

      if (chat.length === 0) return;
      if (isLoading) return;

      if (chat[0]?.position === 0) {
        ScreenMessages.info({
          content: "No hay más mensajes",
          key: "loading",
          duration: 2,
        });
        return;
      }

      ScreenMessages.loading({
        content: "Cargando mensajes...",
        key: "loading",
        duration: 1,
      });

      const lastMessage = chat.at(0);
      const limit = 30;

      const messages = await getInitialMessagesV2(
        serviceId,
        chatPhaseSelected.key,
        limit,
        lastMessage?.position
      );

      const sortedMessages = [...mappingMessages(messages), ...chat].sort(
        (a, b) => a.position - b.position
      );
      setChat(sortedMessages);

      scrollToPosition(lastMessage.position - 1);
    } finally {
      setIsLoading(false); // Marca el fin de la carga
    }
  }, [chatPhaseSelected.key, serviceId, mappingMessages, isLoading]);

  const onNewMessage = useCallback(
    (lastPos) => {
      // if (!messagesLoadRef.current) return;
      const addNewMessage = (snapshot) => {
        newMessage(mappingMessages(snapshot.val()));
      };

      const unsubscribe = getNewMessages(
        serviceId,
        fase,
        addNewMessage,
        lastPos + 1
      );

      return unsubscribe;
    },
    [chat, fase]
  );

  useEffect(() => {    
    let unsubscribe = null;
    setIsLoading(true);
    const getInitMessages = async () => {
      try {
        const initialMessages = await getInitialMessagesV2(
          serviceId,
          chatPhaseSelected.key,
          30
        );

        setChat(mappingMessages(initialMessages));
        unsubscribe = onNewMessage(initialMessages?.at(-1).position);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching initial messages:", error);
      }
    };

    getInitMessages();
    return () => {
      unsubscribe?.();
    };
  }, [countDeleted, countApproved]); //eslint-disable-line

  const handleClickOutside = (event) => {
    setOpenOptions(!openOptions);
  };

  const [scrollUp, seScrollUp] = useState(false);
  const [visibleDate, setVisibleDate] = useState("");

  const handleScroll = async () => {
    const container = chatBodyRef.current;
    const element = chatBodyRef.current;

    if (container) {
      if (element.scrollTop === 0 && !isLoading) {
        await loadMoreMessages();
      }
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      // Calcula si el scroll está cerca de la parte inferior
      const isNearBottom = scrollHeight - scrollTop - clientHeight > 50; // Ajusta el umbral según sea necesario

      seScrollUp(isNearBottom);
       // Find the last visible message date
       const visibleMessages = chat.filter((item, index) => {
        const messageElement = document.getElementById(`message-${index}`);
        if (messageElement) {
          const rect = messageElement.getBoundingClientRect();
          return rect.top >= 0 && rect.bottom <= window.innerHeight;
        }
        return false;
      });

      if (visibleMessages.length > 0) {
        const lastVisibleMessage = visibleMessages[visibleMessages.length - 1];
        const formattedDate = formatDate(lastVisibleMessage.timestamp);
        setVisibleDate(formattedDate); // Set the visible date
      }
    }
  };
  const lastMessage = useMemo(() => chat.at(-1), [chat]); //chat[chat.length - 1]

  useEffect(() => {
    const container = chatBodyRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [chat]);

  return (
    <section
      className="bitacle-chat-body"
      ref={chatBodyRef}
      onClick={handleClickOutside}
      onScroll={handleScroll}
    >
      {isLoading && <Spin size="large" />}
      <div className="visible-date">{visibleDate}</div>
      {chat?.map((item, index, array) => {
        return (
          <Message
            onUpdateMessage={updateMessage}
            setCountDeleted={setCountDeleted}
            setCountApproved={setCountApproved}
            phase={chatPhaseSelected}
            id={serviceId}
            allOpenOptions={openOptions}
            key={index}
            item={item}
            index={index}
            array={array}
            user={user}
          />
        );
      })}
      {scrollUp && (
        <a href={"#message-" + lastMessage?.position}>
          <DownCircleFilled className="down-circle-icon" />
        </a>
      )}
    </section>
  );
}
