import React from "react";
import "./progressBar.css";
import { capitalize } from "lodash";

function ProgressBar({
  phases,
  total,
  setShowModal,
  serviceId,
  setServiceId,
  showTotalProgress,
}) {
  return (
    <div
      className="progress-bar-container"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        // setShowModal(true);
        // setServiceId(serviceId);
      }}
    >
      <div className="progress-bar">
        {phases.map((phase, index) => (
          <div
            key={index}
            className="progress-bar-phase"
            style={{
              backgroundColor: "#f5f5f5",
              width: `${100 / phases.length}%`,
              marginLeft: index === 0 ? "0" : "-2px",
              position: "relative",
            }}
          >
            <div
              className="progress-bar-progress"
              style={{
                width: `${phase.progress}%`,
                backgroundColor: phase.color,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
              }}
            ></div>
          </div>
        ))}
      </div>
      <div className="progress-bar-phase-names">
        {phases.map((phase, index) => (
          <div key={index} className="progress-bar-phase-name">
            {capitalize(phase.name)}
          </div>
        ))}
      </div>
      {showTotalProgress ? total + "%" : ""}
    </div>
  );
}

export default ProgressBar;
