import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import ALERT_ICON_SVG from "../../../assets/icons/svg/info.svg";
import { LoadingPopup } from "../../../components/atoms/popup/Popup";
import useReduxState from "../../../custom-hooks/useReduxState.js";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons";
import { PopupRejectEvent } from "../../../popups/psPopups/PopupRejectEvent.jsx";
import PopupServiceDetails from "../../../popups/psPopups/PopupServiceDetails";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import { setGlobalState } from "../../../reducers/globalState.reducer.js";
import { updateStateEntry } from "../../../reducers/state.entry.reducer";
import { PRINCIPAL_PATH } from "../../../router/routes";
import { existUser } from "../../../services-controller/auth";
import {
  getAllEntriesByService,
  getManagementById,
  getServiceById,
  readEntry,
  readMessages,
} from "../../../services-controller/http.cleinte.controller";
import { currentUser } from "../../../services-firebase/auth";
import PsAssistantsListController from "../../services-provider/ps-assistants/PsAssistantsListController.jsx";
import { getPercentPhases } from "../ps-new-entry/phases";
import "./psSeguimiento.css";
import PsServiceBitacle from "./PsServiceBitacle";
import PsServiceController from "views/menu/services/PsServiceController";
const PsSeguimiento = ({ user = null, history, pay, callbackPayment }) => {
  moment.locale("es");
  let { idService, id } = useParams();
  const TypeUser = user === null ? "sp" : user;
  const dispatch = useDispatch();
  const [entriesList, setEntriesList] = useState([]);
  const [service, setService] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [showServiceDetails, setShowServiceDetails] = useState(false);
  const [showSelectedHelpers, setShowSelectedHelpers] = useState(false);
  const [showCompletedConfirmation, setShowCompletedConfirmation] =
    useState(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);
  const [showFinishedDay, setShowFinishedDay] = useState(false);
  const [management, setManagement] = useState(null);
  const [removeSelectedHelpers, setRemoveSelectedHelpers] = useState(false);
  const [uid, setUser] = useState("");
  const [showRejectEvent, setShowRejectEvent] = useState(false);
  const [procedureUrl, setProcedureUrl] = useState("");
  const [activeId, setActiveTab] = useState(0);
  const [globalState, dispatchGlobalState] = useReduxState(
    { setGlobalState },
    "globalState"
  );

  const percentAvance =
    service?.state?.globalState?.progress?.progress * 100 || 0;
  const useId = idService !== undefined ? idService : id;

  const fetchEntriesById = async () => {
    const uid = currentUser().uid;
    setUser(uid);

    const [entries, service] = await Promise.all([
      getAllEntriesByService(useId),
      getServiceById(useId),
    ]);

    if (user === "client") {
      entries.bitacle = entries.bitacle.filter((bitacleEntry) => {
        bitacleEntry.entrys = bitacleEntry.entrys.filter(
          (entry) => entry.is_visible
        );
        return bitacleEntry.entrys.length > 0;
      });

      setEntriesList(entries.bitacle.reverse());
    } else {
      setEntriesList(entries.bitacle.reverse());
    }

    setService({ ...service, unique_key: idService });
    dispatchGlobalState.setGlobalState(service.state.globalState);
    setProcedureUrl(entries.url);
    if (entries.bitacle.length > 0 && service.state.stage === 10) {
    }

    if (service.metadata.property.management !== "") {
      const management = await getManagementById(
        service.metadata.property.management
      );
      setManagement(management);
    }

    setIsLoader(false);
  };

  useEffect(() => {
    if (!existUser()) {
      history.push(PRINCIPAL_PATH);
    } else {
      fetchEntriesById();
    }

    return () => {};
  }, [useId]);

  const callbackNewEntry = () => {
    history.push(`/services/entry/${useId}`);
  };

  const tabList = [
    { label: "Bitacora", id: 1 },
    { label: "Entradas", id: 0 },
  ];

  const callbackNewUnexpected = () => {
    history.push(`/services/newUnexpected/${useId}`);
  };

  const moreValues = [
    ["Nueva entrada", callbackNewEntry],
    ["Nueva Imprevisto", callbackNewUnexpected],
  ];

  const markEntryAsRead = async (entry, isClient, service) => {
    await readEntry(useId, entry.unique_key, "sp", entry);
    if (
      (entry.isNewClientEntry && isClient === "client") ||
      service.isNewClientEntry
    ) {
      await readEntry(useId, entry.unique_key, "client", entry);
    }
  };

  const markMessageAsRead = async (entry, roleType) => {
    switch (roleType) {
      case "client":
        if (entry?.statusMessages?.newClientMessage) {
          await readMessages(useId, entry?.unique_key, "client", user);
        }
        break;
      case "sp":
        if (entry?.statusMessages?.newSpMessage) {
          await readMessages(useId, entry?.unique_key, "sp", user);
        }
        break;
      default:
        return null;
    }
  };

  const callbackEntryInfo = async (entry) => {
    let targetEntry = entriesList
      .flatMap((entrys) => entrys.entrys)
      .find((newEntry) => newEntry.unique_key === entry.unique_key);

    await markEntryAsRead(
      targetEntry,
      user === "client" ? "client" : false,
      service
    );
    await markMessageAsRead(targetEntry, user === "client" ? "client" : false);
    dispatch(
      updateStateEntry({
        object: targetEntry,
        id: useId,
        userType: user === "client" ? "client" : "sp",
        service: service,
      })
    );

    history.push({
      pathname: `/services/info/entry/${useId}/${targetEntry.unique_key}`,
    });
  };

  const callbackCloseServiceDetails = () => {
    setShowServiceDetails(false);
  };

  const callbackCloseAssistantsPopup = () => {
    setShowSelectedHelpers(false);
    setRemoveSelectedHelpers(false);
  };

  const confirmCompleted = async () => {
    setShowCompletedConfirmation(false);
    setShowCompletedPopup(true);
  };

  const rejectCompleted = () => {
    setShowCompletedConfirmation(false);
  };

  const callbackCloseCompleted = () => {
    setShowCompletedPopup(false);
    history.push(PRINCIPAL_PATH);
  };

  const callbackCloseFinishedDay = () => {
    setShowFinishedDay(false);
    history.push(PRINCIPAL_PATH);
  };

  const callbackCloseRejectEvent = () => {
    setShowRejectEvent(false);
  };

  let [phases, totalPercent] = getPercentPhases(service?.state?.globalState, service?.bitacle_v, service?.state?.stage);
  return (
    <>
      <LoadingPopup state={isLoader} />

      {service?.version === 2 && service?.bitacle_v === 2 ? (
        <PsServiceController service={service} history={history} />
      ) : (
        <PsServiceBitacle
          user={user}
          TypeUser={TypeUser}
          service={service}
          useId={useId}
          callbackEntryInfo={callbackEntryInfo}
          entriesList={entriesList}
          phases={phases}
          totalPercent={totalPercent}
          activeId={activeId}
          setActiveTab={setActiveTab}
          tabList={tabList}
          pay={pay}
          callbackPayment={callbackPayment}
          percentAvance={percentAvance}
          moreValues={moreValues}
        />
      )}

      {showServiceDetails && (
        <StateButtonModalHook
          component={PopupServiceDetails}
          hook={[showServiceDetails, setShowServiceDetails]}
          object={{
            service: service,
            callbackClose: callbackCloseServiceDetails,
          }}
        />
      )}

      {showSelectedHelpers && (
        <StateButtonModalHook
          component={PsAssistantsListController}
          hook={[showSelectedHelpers, setShowSelectedHelpers]}
          object={{
            service: service,
            callbackCloseAssistantsPopup: callbackCloseAssistantsPopup,
            id: useId,
          }}
        />
      )}

      {showCompletedConfirmation && (
        <StateButtonModalHook
          component={PopupTwoOptionWithIcon}
          hook={[showCompletedConfirmation, setShowCompletedConfirmation]}
          object={{
            callbackNegative: rejectCompleted,
            callbackPositive: confirmCompleted,
            textPositive: "Si",
            textNegative: "No",
            popupText:
              "¿Desea terminar el servicio? Esta acción solo se debe realizar una vez el servicio este finalizado en su totalidad.",
            popupIcon: ALERT_ICON_SVG,
          }}
        />
      )}

      {showCompletedPopup && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showCompletedPopup, setShowCompletedPopup]}
          object={{
            message: "¡Servicio completado exitosamente!",
            callback: callbackCloseCompleted,
            btnText: "Ok ",
          }}
        />
      )}

      {showFinishedDay && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[showFinishedDay, setShowFinishedDay]}
          object={{
            message:
              "Has finalizado la obra por el día de hoy. La fecha de ejecución del servicio ha sido actualizada.",
            callback: callbackCloseFinishedDay,
            btnText: "Ok ",
          }}
        />
      )}

      {removeSelectedHelpers && (
        <StateButtonModalHook
          component={PsAssistantsListController}
          hook={[removeSelectedHelpers, setRemoveSelectedHelpers]}
          object={{
            service: service,
            callbackCloseAssistantsPopup: callbackCloseAssistantsPopup,
            id: useId,
            removeHelpers: true,
          }}
        />
      )}

      {showRejectEvent && (
        <StateButtonModalHook
          component={PopupRejectEvent}
          hook={[showRejectEvent, setShowRejectEvent]}
          object={{
            message:
              "Lo sentimos, solo el prestador de servicio asignado al caso puede finalizar el día.",
            btnText: "OK",
            callback: callbackCloseRejectEvent,
            icon: FROWN_ICON_SVG,
          }}
        />
      )}
    </>
  );
};

export default withRouter(PsSeguimiento);
