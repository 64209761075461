import React from "react";
import { PS_SERVICES, SERVICES } from "router/routes";
import PsSeguimientoContainer from "./PsSeguimientoContainer";
import ProgressBar from "components/atoms/progess-bar/ProgressBar";
import { Appbar } from "components/atoms/appbar/Appbar";
import { BtnPayPayU } from "components/atoms/button/Button";
import PsButtonNavbar from "components/molecules/buttonNavbar/PsButtonNavbar";
import UserMenuDesktop from "../../../components/molecules/menu/UserMenu";
import ServiceProviderMenuDesktop from "../../../components/molecules/menu/ServiceProviderMenu";

const shortDescription = (descriptionString) => {
  let arr = descriptionString.split("");
  if (arr.length > 200) {
    arr.splice(200, arr.length - 200, "...");
    return arr.join("");
  } else {
    return descriptionString;
  }
};

const PsServiceBitacle = ({
  user,
  TypeUser,
  service,
  useId,
  callbackEntryInfo,
  entriesList,
  phases,
  totalPercent,
  activeId,
  setActiveTab,
  tabList,
  pay,
  callbackPayment,
  percentAvance,
  moreValues,
}) => {
  return (
    <div className="new-service-container">
      {user === "client" ? (
        <UserMenuDesktop page={SERVICES} showWhatsappButton={true} />
      ) : (
        <ServiceProviderMenuDesktop
          page={PS_SERVICES}
          showSeguimientoOptionsButton={true}
          moreValues={moreValues}
        />
      )}

      <div className="ps-services-list-container desktop-container">
        <div className="new-service-appbar-container">
          {service?.state?.globalState === undefined ? (
            <Appbar
              smallText="Seguimiento"
              bigText={`Caso ${useId}`}
              isTogle={false}
            />
          ) : (
            <Appbar
              smallText="Seguimiento"
              bigText={`Caso ${useId}`}
              isTogle={true}
              activeTab={activeId}
              setActiveTab={setActiveTab}
              tabList={tabList}
            />
          )}
        </div>

        <div className="my-services-container seguimiento-container">
          {service?.state?.globalState !== undefined ? (
            <div style={{ padding: "20px" }}>
              <div className="seguimiento-header">
                <div className="seguimiento-header-title">
                  Genial! vamos al <span>{Math.round(percentAvance)}%</span> de
                  cumplir tu sueño
                </div>
                <ProgressBar phases={phases} total={totalPercent} />
              </div>
              <PsSeguimientoContainer
                props={{
                  user: TypeUser,
                  callbackEntryInfo: callbackEntryInfo,
                  entriesList: entriesList,
                  serviceId: useId,
                  stage: service?.state?.stage,
                }}
                activeId={activeId}
              />
            </div>
          ) : (
            <PsSeguimientoContainer
              props={{
                user: TypeUser,
                callbackEntryInfo: callbackEntryInfo,
                entriesList: entriesList,
                serviceId: useId,
              }}
              activeId={0}
            />
          )}

          {parseInt(service?.state?.stage) === 12 && user === "client" && (
            <BtnPayPayU
              callbackPayment={callbackPayment}
              serviceId={useId}
              email={
                service?.metadata?.user?.register_data?.contact.email_associated
              }
              description={shortDescription(
                `Justo Pago: ${service?.request?.general_description}`
              )}
              pay={pay}
              completed={true}
            />
          )}

          <div className="menu-navbar-container">
            {user !== "client" && (
              <PsButtonNavbar
                page={PS_SERVICES}
                showSeguimientoOptionsButton={service?.state?.stage !== 6}
                moreValues={moreValues}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PsServiceBitacle;
