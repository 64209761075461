import { Drawer, Modal } from "antd";
import React, { useState } from "react";
//import { useRef } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AddNewOptions from "./components/AddNewOptions";
import OrderRequestService from "views/OrderRequestService/OrderRequestService";
import NewUnexpectedForm from "views/newUnexpectedForm/newUnexpectedForm";
import OrderAdditionalService from "views/OrderRequestService/OrderAdditionalService";
import NavBar from "./NavBar";

const NavBarController = ({ isInWorkerPlan = false }) => {
  const { id } = useParams();
  const history = useHistory();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const [isNotificationFormOpen, setIsUnexpectedModalOpen] = useState(false);

  const [showRequestForm, setShowRequestForm] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [isDialogToConfirm, setIsDialogToConfirm] = useState(false);
  const [confirmMaterialModal, setConfirmMaterialModal] = useState(false);
  const [showAdditionalService, setShowAdditionalService] = useState(false);
  //const [chooseOrderType, setChooseOrderType] = useState(false);

  function onHandleNewRequest(type) {
    if (type === "unexpected") {
      return setIsUnexpectedModalOpen(true);
    }
    if (type === "order") {
      //setChooseOrderType(true);
      setIsAddNewModalOpen(false);

      setShowAdditionalService(true)
    } else {
      setShowRequestForm(true);
    }

    setRequestType(type);
  }

  function onCloseRequestForm() {
    setShowRequestForm(false);
  }
  //const anchors = [window.innerHeight * 0.9, window.innerHeight * 0.9];
  //const anchorsOrder = [window.innerHeight * 0.7, window.innerHeight * 0.7];

  //const anchorRef = useRef(null);
  return (
    <>
      <NavBar
        history={history}
        isInWorkerPlan={isInWorkerPlan}
        id={id}
        handleOpenNewOptions={setIsAddNewModalOpen}
      />
      <Modal
        centered
        closable
        maskClosable
        footer={null}
        title={null}
        open={
          isAddNewModalOpen &&
          !showRequestForm &&
          !isDialogToConfirm &&
          !showAdditionalService &&
          !isNotificationFormOpen
        }
        onCancel={() => {
          setIsAddNewModalOpen(false);
        }}
        rootClassName="options-modal"
      >
        <AddNewOptions onHandleNewRequest={onHandleNewRequest} />
      </Modal>
      <OrderRequestService
        open={showRequestForm}
        closeRequest={onCloseRequestForm}
        requestType={requestType}
        openDialogToConfirm={() => setIsDialogToConfirm(true)}
        setCloseModal={() => setIsAddNewModalOpen(false)}
        closeConfirm={() => setIsDialogToConfirm(false)}
        setOpenDateForm={() => setShowRequestForm(true)}
        isDialogToConfirm={isDialogToConfirm}
      />

      <Drawer
          open={isNotificationFormOpen}
        title="Nuevo Imprevisto"
        placement="left"
        width="100vw"
        onClose={() => {
          setIsUnexpectedModalOpen(false);
        }}
        styles={
          { color: "white" }
        }
      >
            <NewUnexpectedForm
              onClose={() => {
                setIsUnexpectedModalOpen(false);
              }}
            />

      </Drawer>
{/* 
      {chooseOrderType && (
        <FloatingPanel
          open={chooseOrderType}
          anchors={anchorsOrder}
          ref={anchorRef}
        >
          <div style={{ margin: "8px" }}>
            <Dropdown>
              <Dropdown.Item
                key="sorter"
                title={<h3>Escoge el pedido que deseas realizar</h3>}
              >
                <div style={{ padding: 12 }}>
                  <Radio.Group defaultValue="default">
                    <Space direction="vertical" block>
                      <Radio block value="default">
                        Segundo pedido
                      </Radio>
                      <Radio block value="nearest">
                        Tercer pedido
                      </Radio>
                      <Radio block value="top-rated">
                        Pedido adicional
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </Dropdown.Item>
            </Dropdown>
          </div>
        </FloatingPanel>
      )} */}

      {showAdditionalService && (
        <OrderAdditionalService
          requestType={requestType}
          openDialogToConfirm={() => setConfirmMaterialModal(true)}
          isDialogToConfirm={confirmMaterialModal}
          closeConfirm={() => setConfirmMaterialModal(false)}
          open={showAdditionalService}
          setOpenForm={() => setShowAdditionalService(true)}
          setCloseForm={() => setShowAdditionalService(false)}
          setCloseModal={() => setIsAddNewModalOpen(false)}
        />
      )}
    </>
  );
};

export default NavBarController;
