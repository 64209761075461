import { Button, ConfigProvider } from "antd";
import { ReactComponent as BellLightIcon } from "assets/icons/svg/bell-light.svg";
import { ReactComponent as TimelineIcon } from "assets/icons/svg/map-icon.svg";
import {
  ALL_SERVICES_ADDED,
  CHECK_QR_CODE,
  NOTIFICATIONS,
  PROFILE,
  PS_BASE_MENU_PATH,
  ROUTE_TIMELINE,
} from "router/routes";
import "./NavBar.css";

import {
  PlusOutlined,
  ScanOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UnorderedListOutline } from "antd-mobile-icons";

export default function NavBar({
  history,
  isInWorkerPlan,
  id,
  handleOpenNewOptions,
}) {
  return (
    <>
      <nav className="nav-bar">
        <ConfigProvider theme={{}}>
          {!isInWorkerPlan ? (
            <Button
              size="large"
              onClick={() => {
                history.push(ROUTE_TIMELINE);
              }}
              data-active={history.location.pathname.includes(ROUTE_TIMELINE)}
              className="nav-bar-opt"
            >
              <TimelineIcon />
            </Button>
          ) : (
            <Button
              size="large"
              onClick={() => {
                history.push(ALL_SERVICES_ADDED);
              }}
              data-active={history.location.pathname.includes(
                ALL_SERVICES_ADDED
              )}
              className="nav-bar-opt"
            >
              <UnorderedListOutline />
            </Button>
          )}
          <Button
            size="large"
            onClick={() => {
              history.push(CHECK_QR_CODE);
            }}
            data-active={history.location.pathname.includes(CHECK_QR_CODE)}
            className="nav-bar-opt"
          >
            <ScanOutlined />
          </Button>
          {!isInWorkerPlan ? (
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                history.push(ALL_SERVICES_ADDED);
              }}
              className="nav-bar-opt-center"
            >
              <SendOutlined />
            </Button>
          ) : (
            <Button
              size="large"
              shape="circle"
              onClick={() => {
                handleOpenNewOptions(true);
              }}
              className="nav-bar-opt-center-ser"
            >
              <PlusOutlined />
            </Button>
          )}

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + NOTIFICATIONS);
            }}
            data-active={history.location.pathname.includes(NOTIFICATIONS)}
            className="nav-bar-opt"
          >
            <BellLightIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + PROFILE);
            }}
            data-active={history.location.pathname.includes(PROFILE)}
            className="nav-bar-opt"
          >
            <UserOutlined />
          </Button>
        </ConfigProvider>
      </nav>
    </>
  );
}
