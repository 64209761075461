import { BellOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import CASH_ICON from "../../../assets/icons/svg/cash.svg";
import WORKER_ICON from "../../../assets/icons/svg/worker_m.svg";
import COUCH_ICON from "../../../assets/icons/svg/couch.svg";
import FORKLIFT_ICON from "../../../assets/icons/svg/forklif.svg";

export const tabs = [
  {
    key: "notifications",
    title: "Notificaciones",
    icon: (active) =>
      active ? <BellOutlined style={{ color: "#fd992f" }} /> : <BellOutlined />,
  },
  {
    key: "home",
    title: "Inicio",
    icon: (active) =>
      active ? (
        <HomeOutlined className="home-icon-bar" style={{ color: "#fd992f" }} />
      ) : (
        <HomeOutlined className="home-icon-bar" />
      ),
  },
  {
    key: "profile",
    title: "Perfil",
    icon: (active) =>
      active ? <UserOutlined style={{ color: "#fd992f" }} /> : <UserOutlined />,
  },
];

export const auxiliarPhasesArray = [
  {
    name: "Gestión de cliente",
    id: 3,
    style_id: "comercial",
    phase: { code: 3 },
  },
  {
    name: "Diseño",
    id: 0,
    style_id: "design",
    phase: { code: 0 },
  },
  {
    name: "Planeación",
    id: 1,
    style_id: "planeation",
    phase: { code: 1 },
  },
  {
    name: "Operaciones",
    id: 2,
    style_id: "operations",
    phase: { code: 2 },
  },
];

export const PHASE_ICONS = {
  comercial: CASH_ICON,
  design: COUCH_ICON,
  planeation: FORKLIFT_ICON,
  operations: WORKER_ICON,
};
