import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Tabs, Card, List, Button, Table } from 'antd';
import { get } from '../../services/handler.request';
import "./newUnexpectedForm.css";

const { TabPane } = Tabs;

const NewUnexpectedForm = () => {
  const [form] = Form.useForm();
  const [allServices, setAllServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const services = await get('logistic/objects');
      setAllServices(services);
      setLoading(false);
    };
    fetchData();
  }, []);

  const filteredServices = useMemo(() => {
    return allServices.filter((service) =>
      service.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [allServices, searchTerm]);

  const handleServiceSelect = (service) => {
    setSelectedServices((prev) => {
      if (prev.some((s) => s.id === service.id)) {
        return prev;
      }
      return [...prev, service];
    });
    setActiveTab("2");
  };

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="steps-container-new-unexpected">
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Información Básica" key="1">
          <h4>Información del Proyecto</h4>
          <Form layout="vertical">
            <Form.Item label="Costo">
              <Input placeholder="Ingrese el costo" />
            </Form.Item>
            <Form.Item label="Días adicionales de obra">
              <Input placeholder="Ingrese los días adicionales" />
            </Form.Item>
          </Form>
          <h4>Servicios Seleccionados</h4>
          <Table
            dataSource={selectedServices}
            columns={[
              { title: 'Nombre', dataIndex: 'name', key: 'name' },
              { title: 'Detalles', dataIndex: 'details', key: 'details' }
            ]}
            rowKey="id"
            pagination={false}
          />
          <Button type="primary" onClick={() => setActiveTab("2")} style={{ marginTop: 16 }}>
            Seleccionar Servicios
          </Button>
        </TabPane>
        <TabPane tab="Servicios" key="2">
          <Input
            placeholder="Buscar servicio..."
            value={searchTerm}
            onChange={onSearch}
            style={{ marginBottom: 16 }}
          />
          <List
            loading={loading}
            dataSource={filteredServices}
            renderItem={(service) => (
              <Card
                hoverable
                onClick={() => handleServiceSelect(service)}
                style={{
                  marginBottom: 8,
                  border: selectedServices.some((s) => s.id === service.id) ? "2px solid #ffae18" : "1px solid #ddd",
                  textAlign: "center",
                }}
                cover={<img alt={service.name} src={service.images?.at(0)?.url ?? ""} style={{ height: 150, objectFit: 'cover' }} />}
              >
                <Card.Meta title={service.name} description={service.details} />
              </Card>
            )}
          />
        </TabPane>
        <TabPane tab="Espacios" key="3">
          <h4>Espacios para los Servicios</h4>
          <div>Selecciona los espacios donde se aplicarán los servicios.</div>
        </TabPane>
        <TabPane tab="Confirmar" key="4">
          <div>Revisa y confirma la información antes de enviar.</div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default NewUnexpectedForm;
