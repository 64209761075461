import { produce } from "immer";
import { useState } from "react";
import { useImperativeFilePicker } from "use-file-picker";
import { convertHeicToJpg } from "../components/atoms/mediaSource/imageConverter";
import { message } from "antd";

const buildSRCMedia = (type, url, name) => {
  return {
    type,
    url,
    name,
  };
};

export default function useFile(
  resources,
  dispatch,
  setReload,
  limitFilesToUp,
  filesAccept
) {

  const [showMediaSource, setShowMediaSource] = useState(false);
  const [mediaSourceFile, setMediaSourceFile] = useState(null);
  const [removeMedia, setRemoveMedia] = useState(false);
  const [indexResource, setIndexResorunce] = useState(-1);

  const buildObjectResource = async (content, setReload) => {
    let { type, name } = content;
    let typeFile = "";
    let nameFile = name.split(".")[0];
    if (name.toLowerCase().includes("heic")) {
      type = "image/heic";
    }

    switch (type) {
      case "application/pdf":
        typeFile = "pdf";
        break;
      case "image/heic":
        setReload(true);
        await convertHeicToJpg(content).then((blob) => {
          content = new File([blob], nameFile + ".jpg", { type: blob.type });
        });
        typeFile = "image";
        setReload(false);
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        typeFile = "excel";
        break;
      default:
        typeFile = type.split("/")[0];
    }

    const object = buildSRCMedia(
      typeFile,
      URL.createObjectURL(content),
      nameFile
    );

    return object;
  };

  const callbackAddFile = async (e) => {
    const { type, name } = e;
    let typeFile = "";
    let nameFile = name.split(".")[0];

    switch (type) {
      case "application/pdf":
        typeFile = "pdf";
        break;
      case "image/heic":
        setReload(true);
        await convertHeicToJpg(e).then((blob) => {
          e = new File([blob], nameFile + ".jpg", { type: blob.type });
        });
        setReload(false);
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        typeFile = "excel";
        break;
      default:
        typeFile = type.split("/")[0];
    }

    const resourcesUpdate = produce(resources, (draftResources) => {
      draftResources.push(
        buildSRCMedia(typeFile, URL.createObjectURL(e), nameFile)
      );
    });
    callbackUpdateResourceList(resourcesUpdate);
  };



  const addNewImage = async (file, dispatcher) => {

    const resource = await buildObjectResource(file);
    dispatcher(resource);

  }
  const callbackAddFileError = () => { };

  const callbackViewSource = (mediaFile) => {
    setMediaSourceFile(mediaFile);
    setShowMediaSource(true);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const deleteMediaSource = async (index) => {
    setIndexResorunce(index);
    setRemoveMedia(true);
  };

  const callbackKeepMediaSource = () => {
    setRemoveMedia(false);
  };

  const callabackDeleteInmediateMediaSource = (indexResource) => {
    const resourcesUpdate = produce(resources, (draftResources) => {
      draftResources.splice(indexResource, 1);
    });
    callbackUpdateResourceList(resourcesUpdate);
    removeFileByIndex(indexResource);
  };

  const callbackUpdateResourceList = (list) => dispatch(list);

  const [
    openFileSelector,
    { filesContent, loading, errors, plainFiles, clear, removeFileByIndex },
  ] = useImperativeFilePicker({
    multiple: true,
    accept: filesAccept || [".heic", ".png", ".jpg", ".jpeg", ".pdf", ".xlsx"],
    maxFileSize: 200,
    readAs: "DataURL",
    readFilesContent: true,
    limitFilesConfig: {
      max: limitFilesToUp,
      min: 0,
    },
    // eslint-disable-next-line no-unused-vars
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
    },
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      console.log("onFilesRejected", errors);
    },
    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      message.loading({
        content: "Procesando Archivos",
        key: "upFiles"
      });
      // this callback is called when there were no validation errors
      let newResources = [...resources];
      for (let file of plainFiles) {
        const objectBuild = await buildObjectResource(file, setReload);
        objectBuild.unload = true;
        newResources.push(objectBuild);
      }

      callbackUpdateResourceList(newResources);
      clear();
      message.destroy("upFiles");
    },
  });

  const resourcesContent = () => {
    const realList = [];
    for (let file of filesContent) {
      const { name, content } = file;

      let typeFile = name.split(".")[1];

      let nameFile = name.split(".")[0];

      realList.push(buildSRCMedia(typeFile, content, nameFile));
    }
    return realList;
  };

  const callbackRemoveMediaSource = () => {
    if (indexResource !== -1) {
      const resourcesUpdate = produce(resources, (draftResources) => {
        draftResources.splice(indexResource, 1);
      });
      callbackUpdateResourceList(resourcesUpdate);
      setRemoveMedia(false);
    }
    setIndexResorunce(-1);
    removeFileByIndex(indexResource);
  };

  const utils = {
    filesContent,
    loading,
    errors,
    plainFiles,
    clear,
    removeFileByIndex,
    addNewImage,
  };

  return {
    openFileSelector,
    callbackAddFile,
    utils,
    resourcesContent,
    loading,
    buildObjectResource,
    callbackAddFileError,
    callbackViewSource,
    callbackCloseMediaSource,
    deleteMediaSource,
    callbackKeepMediaSource,
    callbackRemoveMediaSource,
    setRemoveMedia,
    setMediaSourceFile,
    setShowMediaSource,
    setIndexResorunce,
    callbackUpdateResourceList,
    showMediaSource,
    mediaSourceFile,
    removeMedia,
    indexResource,
    callabackDeleteInmediateMediaSource,
    buildSRCMedia,
  };
}

