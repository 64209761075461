import {
  Button,
  Form,
  Mentions,
  message as ScreenMessages,
  Popover,
} from "antd";
import { ReactComponent as MicrophoneIcon } from "assets/icons/svg/chat-microphone.svg";
import { ReactComponent as PaperclipIcon } from "assets/icons/svg/chat-paperclip.svg";
import { ReactComponent as SendIcon } from "assets/icons/svg/chat-send.svg";
import useReduxState from "custom-hooks/useReduxState";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getEmisorFromUser } from "res/utils";
import {
  createNewMessage,
  getAllWorkers,
} from "services-controller/http.cleinte.controller";
import "./ChatFooter.css";
import FileSelector from "./components/fileSelector/FileSelector";
import SoundRecorder from "./components/soundRecorder/SoundRecorder";
import PersonInfo from "components/atoms/time-lines/entryCard/ProfileContainer";
import { getUsersTags } from "res/roles";

export default function ChatFooter({ dispatchers }) {
  const [form] = Form.useForm();
  const messageWatch = Form.useWatch("message", form);
  const [{ chat, chatPhaseSelected, phasesStatus }] = useReduxState(
    {},
    "bitacle"
  );
  const servicesList = useSelector(
    (state) => state.services_list.servicesList,
    shallowEqual
  );

  const textAreaRef = useRef(null);
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false);
  const [isSelectingMention, setIsSelectingMention] = useState(false);
  const [isSendBtnPopoverOpen, setIsSendBtnPopoverOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [serviceProviderItems, setServiceProviderItems] = useState([]);
  const [allServiceProviders, setAllServiceProviders] = useState([]);
  const [usersTagged, setUsersTagged] = useState({});

  const { newMessage } = dispatchers;

  const onSubmit = useCallback(async () => {
    try {
      setSending(true);
      const { message } = form.getFieldsValue();
      const emisor = getEmisorFromUser(user);

      let obj = {
        timestamp: new Date().toISOString(),
        is_valid: false,
        position: chat.length,
        emisor: emisor,
        user: emisor.name,
        message: message,
        admin_read: true,
        admin_read_date: new Date().toISOString(),
        client_read: false,
        client_read_date: "",
        sp_read: false,
        sp_read_date: "",
        type: "message",
        images: [],
        avatar: emisor.thumbnail,
        phase: {
          name: chatPhaseSelected.name,
          code: chatPhaseSelected.key,
        },
        tags: {
          count: Object.keys(usersTagged).length,
          tags: usersTagged,
        },
      };

      await createNewMessage(
        id,
        chatPhaseSelected.key,
        user?.register_type === 200 ? "sp" : "client",
        obj,
        emisor
      );
      form.resetFields();

      //TODO: state sendign message
      newMessage(obj);
    } catch (error) {
      ScreenMessages.error("Error al enviar el mensaje");
    } finally {
      setSending(false);
    }
  }, [form.getFieldsValue()]); // eslint-disable-line

  const isActivePhase = phasesStatus.find(
    (phase) => phase.id == chatPhaseSelected.key
  );

  const fetchUsers = async () => {
    try {
      const currentService = servicesList.find(
        (service) => service.unique_key === id
      );

      const workers = await getAllWorkers();
      const userTags = await getUsersTags(
        workers,
        currentService.service_providers
      );

      setServiceProviderItems(
        userTags?.map((user) => ({
          value: user?.name,
          label: (
            <PersonInfo
              name={user?.name}
              thumbnailLink={user?.thumbnail}
              id={user?.id}
            />
          ),
          key: !isNaN(user.id) ? user.id.toString() : user.id,
        }))
      );
      setAllServiceProviders(userTags);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (chatPhaseSelected.key) {
      textAreaRef.current?.focus();
    }

    fetchUsers();
  }, [chatPhaseSelected.key]); // eslint-disable-line

  return (
    <>
      {isActivePhase.active && (
        <footer className="bitacle-chat-footer">
          <Form
            id="chat-form"
            form={form}
            className="chat-form"
            onFinish={() => {
              onSubmit();
            }}
          >
            <Form.Item name="message">
              <Mentions
                disabled={sending}
                className="message-input"
                size="large"
                autoFocus
                ref={textAreaRef}
                split=""
                allowClear
                prefix="@"
                placeholder="Escribe tu mensaje"
                onSelect={(val) => {
                  // Delay the "submit" to avoid send a message when selecting a mention
                  setTimeout(() => {
                    setIsSelectingMention(false);
                    const userTagged = allServiceProviders.find(
                      (user) => user.id === val.key
                    );

                    setUsersTagged({
                      ...usersTagged,
                      [val.key]: {
                        len: val.value.length,
                        name: val.value,
                        position: Object.keys(usersTagged).length || 0,
                        userId: val.key,
                        thumbnail: userTagged?.thumbnail,
                      },
                    });
                  }, 40);
                }}
                onSearch={() => {
                  setIsSelectingMention(true);
                }}
                onKeyDown={(mentionEvent) => {
                  if (sending) return;
                  const event = mentionEvent.nativeEvent;

                  // Add a new line when pressing enter + (alt, shift, meta[from apple devices])
                  if (
                    (event.metaKey ||
                      event.getModifierState("AltGraph") ||
                      event.altKey ||
                      event.shiftKey) &&
                    event.key === "Enter"
                  ) {
                    event.preventDefault();
                    form.setFieldsValue({
                      message: `${form.getFieldValue("message")}\n`,
                    });

                    // scroll text area to bottom
                    setTimeout(() => {
                      event.target.scrollTop = event.target.scrollHeight;
                    }, 100);

                    return;
                  }

                  // Avoid adding new line which is the default of textarea
                  if (
                    event.key === "Enter" &&
                    !event?.target?.value?.trim().length
                  ) {
                    event.preventDefault();
                    return;
                  }

                  if (event.key === "Enter") {
                    event.preventDefault();

                    if (!isSelectingMention) {
                      form.submit();
                    }

                    return;
                  }
                }}
                options={serviceProviderItems}
              />
            </Form.Item>
          </Form>

          <Popover
            open={isSendBtnPopoverOpen}
            arrow={false}
            rootClassName="send-btn-popover"
            destroyTooltipOnHide
            content={
              <SoundRecorder
                popoverState={[isSendBtnPopoverOpen, setIsSendBtnPopoverOpen]}
              />
            }
          >
            <Button
              type="primary"
              form="chat-form"
              htmlType={messageWatch?.length ? "submit" : "button"}
              size="large"
              className="send-btn"
              icon={
                messageWatch?.trim()?.length ? <SendIcon /> : <MicrophoneIcon />
              }
              onClick={() => {
                if (!messageWatch?.trim().length) {
                  setIsSendBtnPopoverOpen(!isSendBtnPopoverOpen);
                }
              }}
              disabled={sending}
              loading={sending}
            ></Button>
          </Popover>

          <Popover
            trigger="click"
            rootClassName="file-selector-popover"
            arrow={false}
            placement="topRight"
            open={isFileSelectorOpen}
            destroyTooltipOnHide
            content={
              <FileSelector
                open={isFileSelectorOpen}
                setOpen={setIsFileSelectorOpen}
              />
            }
          >
            <Button
              size="large"
              icon={<PaperclipIcon />}
              className="upload-btn"
              onClick={() => {
                setIsFileSelectorOpen(!isFileSelectorOpen);
              }}
            ></Button>
          </Popover>
        </footer>
      )}
    </>
  );
}
