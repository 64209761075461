import { message } from "antd";
import { convertSnapshotToListV2 } from "callbacks/list";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notification as NotificationComponent } from "antd";
import {
  getAllServicesInProgress,
  getEntryData,
  getNotifications,
  notificationRemoveById,
} from "services-controller/database";
import {
  removeNotifications,
  updateNotification,
} from "services-controller/http.cleinte.controller";
import { countUnreadNotifications } from "utils/utils.notifications";
import { setChatPhaseSelected } from "../reducers/bitacle.reducer";
import useReduxState from "./useReduxState";
import { convertPhaseKey } from "../res/convert.codes";
const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const [, dispatch] = useReduxState({ setChatPhaseSelected }, "bitacle")

  const auth = useSelector((state) => state.auth);
  const isClient = auth.userType === "client";
  const { id } = auth.user ?? {};

  const updateList = useCallback((snapshot) => {
    if (typeof snapshot === "object") {
      const currentList = convertSnapshotToListV2(snapshot.val()).reverse();
      setNotifications(currentList);
      setUnreadNotifications(countUnreadNotifications(currentList));
    }
  }, []);

  useEffect(() => {
    const listener = getNotifications(updateList);

    let mounted = true;
    if (mounted) {
      listener();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationOnClick = async (notification, history) => {
    let servicesAssociated = JSON.parse(localStorage.getItem("servicesList"));
    let onFinishRedirect = null
    const restoreServices = () => {
      onFinishRedirect = getAllServicesInProgress((snapshot) => {
        const services = convertSnapshotToListV2(snapshot?.val() ?? {});
        servicesAssociated = services;
        localStorage.setItem("servicesList", JSON.stringify(services));
      });

    };

    if (!servicesAssociated) {
      restoreServices();
    }
    let notificationService = servicesAssociated.find(
      (service) => service.unique_key === notification.serviceId
    );
    if (!notificationService) {
      restoreServices();
      notificationService = servicesAssociated.find(
        (service) => service.unique_key === notification.serviceId
      );
    }

    switch (notification?.type) {
      case "new_message":
        const redirectToNewBitacle = (notificationService && notificationService.bitacle_v == 2) || !isNaN(notification.entryId)
        if (redirectToNewBitacle) {
          const entryData = await getEntryData(notification?.serviceId, notification.entryId);

          const entryKey = entryData.phase?.code ?? notification.entryId
          const phaseName = convertPhaseKey(Number(entryKey))
          dispatch.setChatPhaseSelected({ name: phaseName, key: entryKey })

          // isNaN(notification.entryId) && NotificationComponent.info({
          //   message: "Este mensaje pertenece a la vieja bitacora",
          //   description: "Para encontrar un mensaje buscalo en alguna de las fases de la bitacora",
          //   duration: 0
          // });

          if (!isClient) {
            history.push(`/chat/${notification?.serviceId}/fase/${entryKey}`);
            break;

          }



          history.push(`/chat/${notification?.serviceId}/fase/${entryKey}`);
          break;

        };
        history.push(
          `/services/chat/entry/${notification?.serviceId}/${notification?.entryId}`
        );
        break;
      case "new_entry":
        const entryData = await getEntryData(notification?.serviceId, notification.entry);

        const entryKey = entryData.phase?.code ?? notification.entry
        const phaseName = convertPhaseKey(Number(entryKey))

        if (notificationService?.bitacle_v == 2) { // redirect to new bitacle

          dispatch.setChatPhaseSelected({ name: phaseName, key: entryKey })
          history.push(`/chat/${notification?.serviceId}/fase/${entryKey}`);
          break;
        }


        const route = notification.entry ?
          `/services/chat/entry/${notification?.serviceId}/${notification?.entry}`
          :
          `/details/${notification?.serviceId}/9`;
        history.push(
          route
        );
        break;
      case "general":
        NotificationComponent.info({
          message: notification.title,
          description: notification.message,
          duration: 0
        });
        break;
      default:

        break;
    }
    onFinishRedirect && onFinishRedirect();
    await updateNotification(notification?.unique_key, {
      ...notification,
      read: true,
    });
  };

  const handleMarkAsRead = async (idNotification) => {
    await updateNotification(idNotification, { read: true });
  };

  const handleRemoveById = async (idNotification, notification) => {
    switch (notification) {
      case "notifications":
        try {
          await removeNotifications(idNotification, id);
        } catch (error) {
          console.error(error);
        }
        break;
      case "notifications_appointments":
        await notificationRemoveById(id, notification);
        break;

      default:
        return;
    }
  };

  return {
    notifications,
    handleRemoveById,
    unreadNotifications,
    notificationOnClick,
    handleMarkAsRead,
  };
};

export default useNotifications;
