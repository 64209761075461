import { useCallback, useEffect, useState } from "react";
import FROWN_ICON_SVG from "../../../../assets/icons/svg/frown.svg";
import { Appbar } from "../../../../components/atoms/appbar/Appbar";
import TextFilterComponent from "../../../../components/atoms/button/FilterButton";
import ProgressBar from "../../../../components/atoms/progess-bar/ProgressBar";
import PsButtonNavbar from "../../../../components/molecules/buttonNavbar/PsButtonNavbar";
import { SearchContainer } from "../../../../components/molecules/container/Container";
import ServiceProviderMenuDesktop from "../../../../components/molecules/menu/ServiceProviderMenu";
import { PS_SERVICES } from "../../../../router/routes";
import { getPercentPhases } from "../../ps-new-entry/phases";
import "../psServices.css";
import { completeService } from "./services";

const ServiceProviderMyServicesSearch = ({
  callbackNavigator,
  searchCallback,
  currentMonth,
  changeCurrentCalendar,
  currentCalendar,
  calendarIcon,
  changeView,
  servicesRequests,
  reload,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredServices, setFilteredServices] = useState(servicesRequests);
  const [selectedStatus, setSelectedStatus] = useState();
  const filterServicesByText = useCallback(
    (text, status) => {
      const lowerCaseText = text ? text.toLowerCase() : "";
      const statusNumber = Number(status);
      const filteredServices = servicesRequests.filter((service) => {
        const textCondition =
          !text ||
          service?.metadata?.user?.register_data?.name
            .toLowerCase()
            .includes(lowerCaseText);
        const statusCondition =
          !status || service?.state?.stage === statusNumber;
        return textCondition && statusCondition;
      });
      return filteredServices;
    },
    [servicesRequests]
  );
  const handleTextFilter = useCallback(
    (text, status) => {
      const newServices = filterServicesByText(text, status);
      setFilteredServices(newServices);
    },
    [filterServicesByText]
  );
  useEffect(() => {
    handleTextFilter(searchText, selectedStatus);
  }, [searchText, handleTextFilter, selectedStatus]);
  const onFilter = (text, status) => {
    setSearchText(text);
    setSelectedStatus(status);
  };

  return (
    <div className="usertype-main-container appbar-no-background">
      <ServiceProviderMenuDesktop page={PS_SERVICES} />
      <div className="ps-services-list-container">
        <div className="new-service-appbar-container">
          <Appbar smallText="Tus " bigText="Servicios" />
        </div>
        <div
          className={`my-services-container ${
            changeView ? "change-services-display" : ""
          }`}
        >
          <div className="filter">
            <TextFilterComponent onFilter={onFilter} />
          </div>
          <div className="my-requests-container">
            {filteredServices?.length > 0 ? (
              filteredServices?.map((service, key) => {
                const [phases, totalPercent] = getPercentPhases(
                  service?.state?.globalState,
                  service?.bitacle_v,
                  service?.state?.stage
                );
                return (
                  <div
                    key={key}
                    className="SpSpaceBetweenContainers sc-container-search baseContainer"
                  >
                    <div className="infoContainer sc-space-style">
                      <SearchContainer
                        nameCustomer={
                          service?.metadata?.user?.register_data?.name
                        }
                        obj={service}
                        stage={service?.state?.stage}
                        uniqueKey={service.unique_key}
                        servicesSelected={service?.request?.selected_services}
                        callbackNavigator={callbackNavigator}
                        completeService={completeService}
                        reload={reload}
                      />
                    </div>
                    <div className="progress-container">
                      {service?.state?.globalState !== null && (
                        <ProgressBar phases={phases} total={totalPercent} />
                      )}
                    </div>
                    <div
                      className={` ${
                        service.isNewSpEntry ? "pulse-is-new" : ""
                      } `}
                    ></div>
                    <div
                      className={` ${
                        service.hasNewSpMessage ? "pulse-in-new-message" : ""
                      }`}
                    ></div>
                  </div>
                );
              })
            ) : (
              <div className="no-assistants-container">
                <img src={FROWN_ICON_SVG} className="no-entries-icon" alt="" />
                <div className="no-properties-title">
                  No se han encontrado servicios con los filtros aplicados
                </div>
              </div>
            )}
          </div>
          <div className="menu-navbar-container">
            <PsButtonNavbar page={PS_SERVICES} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceProviderMyServicesSearch;
