import React, { useEffect, useState } from "react";
import NewHome from "../home/NewHome";
import {
  getAllEntriesByService,
  getServiceOperationById,
} from "services-controller/http.cleinte.controller";
import useReduxState from "custom-hooks/useReduxState";
import { setChatPhaseSelected } from "reducers/bitacle.reducer";

const PsServiceController = ({ service, history }) => {
  const [phasesOptions, setPhasesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [haveServiceOperation, setHaveServiceOperation] = useState(false);

  const [, dispatcher] = useReduxState({ setChatPhaseSelected }, "bitacle");
  useEffect(() => {
    if (!service?.unique_key) {
      console.warn("Service or unique_key is not provided");
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await getAllEntriesByService(service.unique_key);

        const entries = response?.bitacle?.[0]?.entrys || [];
        setPhasesOptions(entries);

        const res = await getServiceOperationById(service.unique_key);
        if (res?.workplan) {
          setHaveServiceOperation(true);
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [service]);

  function setChat(phase, index) {
    const name = phase.name;
    dispatcher.setChatPhaseSelected({ key: index, name });
    const route = `/chat/${service.unique_key}/fase/${index}`;
    history.push(route);
  }

  return (
    <NewHome
      loading={loading}
      service={service}
      isInPsView={true}
      phasesOptions={phasesOptions}
      history={history}
      setChat={setChat}
      haveServiceOperation={haveServiceOperation}
    />
  );
};

export default PsServiceController;
