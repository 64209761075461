import React from 'react';
import { Form, Input, Checkbox, Button, Typography, message, notification, InputNumber } from 'antd';
import InputFile from '../../../../components/InputFile/InputFile';
import { uploadMultipleEvidenceResources } from '../../../../services-controller/storage';
import { getEmisorFromUser } from '../../../../res/utils';
import { createNewMessage } from '../../../../services-controller/http.cleinte.controller';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text } = Typography;
const { TextArea } = Input;
// const { Option } = Select;

const SimpleUnexpectedForm = ({serviceId, closeForm}) => {

  const [files, setFiles] = React.useState([]);
  const onDeleteFile = (index) => {
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
  };

  const user = useSelector((state) => state.auth.user, shallowEqual)
 const [isLoading, setIsLoading] = React.useState(false)
  const [form] = Form.useForm()

  const onFinish = async(values) => {

    try {
      setIsLoading(true)
      //upload fi
      // if (!files.length) {
      //   message.error("Debes cargar un archivo");
      //   return;
      // }
      if (files.some(file => !file.type)) {
        message.error("Tipo de archivo no soportado");
        return;
      }
      const resourcesLink = await uploadMultipleEvidenceResources(files);

      const emisor = getEmisorFromUser(user)
      const title = "Imprevisto en operaciones: " + values.name
      const obj = {
        timestamp: new Date().toISOString(),
        is_valid: false,
        // position: chat.length,
        emisor: emisor,
        user: emisor.name,
        message: values.description,
        title,
        unexpected_data:{
          days: values.days,
          overCost: values.overCost,
          phase: 2,
          materialsOverCost: values.materialsOverCost,
          laborOverCost: values.laborOverCost

        },
        admin_read: true,
        admin_read_date: new Date().toISOString(),
        client_read: false,
        client_read_date: "",
        sp_read: false,
        sp_read_date: "",
        type: "activity",
        images: resourcesLink.map(src=>src.url),
        detail: values.description,
        files: resourcesLink,
      };

      const userType = user?.register_type === 200 ? 'sp' : "client"
      await createNewMessage(serviceId, 2, userType, obj, emisor)

      setFiles([])
      notification.info({
        message: 'Imprevisto Enviado',
        description:
          'Se ha enviado el imprevisto a bitácora',
      });
      // reset fields
      form.resetFields()


      closeForm()


    } catch (error) {
      console.log(error)
      message.error('Error al enviar los datos');
    }finally{
      setIsLoading(false)
      setFiles([])
    }
    
  };
  const phaseOptions = [
    { key: "2", label: "Operaciones" },
    { key: "1", label: "Planeación" },
    { key: "0", label: "Diseño" }
  ];
  const addFiles = (data) => {
    setFiles(data)
  }

  const [showOverCostFields, setShowOverCostFields] = React.useState(false);
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', background: '#f5f5f5', borderRadius: '8px' }}>
      <Form layout="vertical" style={{ display: 'flex', flexDirection: 'column' }} onFinish={onFinish}>
        {/* <Form.Item label={<Text strong>Cambia la Fase del Imprevisto</Text>} name="phase">
          <Select placeholder="Operaciones" defaultValue={phaseOptions[0].key}>
            {phaseOptions.map((phase) => (
              <Option key={phase.key} value={phase.key}>
                {phase.label}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Text strong>Rellena La Información del Imprevisto</Text>
        <Form.Item label="Nombre" name="name">
          <Input placeholder="Nombre corto para el imprevisto" />
        </Form.Item>
        <Form.Item label="Días a Adicionar al Calendario" name="days">
          <Input type="number" defaultValue={0} />
        </Form.Item>

        <Form.Item name="overCost" valuePropName="checked">
          <Checkbox onChange={(e) => setShowOverCostFields(e.target.checked)}>¿Genera un Sobre Costo?</Checkbox>
        </Form.Item>



        {showOverCostFields && (
          <>
            <Form.Item
              label="¿Cuánto sobrecosto genera en mano de obra?"
              name="laborOverCost"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder="Costo en mano de obra"
              />
            </Form.Item>

            <Form.Item
              label="¿Qué sobrecosto se genera en materiales?"
              name="materialsOverCost"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder="Costo en materiales"
              />
            </Form.Item>

          </>
        )}

        <Form.Item label={<Text strong>Describe que ocasionó el Imprevisto</Text>} name="description">
          <TextArea rows={4} placeholder="Describe que ocasionó el incident" />
        </Form.Item>



        <Form.Item label={<Text strong>Añade fotos, documentos o videos</Text>}>
          <InputFile
            images={files}
            deleteMediaSource={onDeleteFile}
            onAddFiles={addFiles}
            limit={25}
            filesAccept={[".png", ".jpg", ".heic", ".jpeg", ".pdf"]}
            
          />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: '100%' }}>
            Actualizar bitácora
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SimpleUnexpectedForm;
