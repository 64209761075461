import { useEffect, useRef, useCallback, useState } from "react";
import {
  getInitialMessages,
  getNewMessages,
} from "services-controller/database";

export function useChatMessages(
  serviceId,
  chatPhaseSelected,
  userId,
  dispatchers,
  chat
) {
  const { saveMessages, newMessage, changedMessage, addMoreMessages } =
    dispatchers;

  const [isLoading, setIsLoading] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const chatBodyRef = useRef(null);
  const messagesLoadRef = useRef(false);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      chatBodyRef.current?.scrollTo(0, chatBodyRef.current?.scrollHeight);
    }, 400);
  }, []);

  const scrollToPosition = useCallback((position) => {
    const element = chatBodyRef.current?.querySelector(
      `[data-key="${position}"]`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const mappingMessages = useCallback(
    (messages) =>
      Array.isArray(messages)
        ? messages.map((item) => ({
            ...item,
            isOtherUser: item.emisor?.id !== userId,
          }))
        : { ...messages, isOtherUser: messages.emisor?.id !== userId },
    [userId]
  );

  // useEffect(() => {
  //   const fetchInitialMessages = async () => {
  //     try {
  //       const initialMessages = await getInitialMessages(
  //         serviceId,
  //         chatPhaseSelectedKey,
  //         30
  //       );

  //       await saveMessages(mappingMessages(initialMessages));
  //       messagesLoadRef.current = true;
  //       alert("mensajes iniciales" + String(messagesLoadRef.current))
  //       scrollToBottom();
  //     } catch (error) {
  //       console.error("Error fetching initial messages:", error);
  //     }
  //   };

  //   fetchInitialMessages();

  //   return () => {
  //     messagesLoadRef.current = false;
  //   };
  // }, [
  //   serviceId,
  //   chatPhaseSelectedKey,
  //   //saveMessages,
  //   //mappingMessages,
  //   scrollToBottom,
  // ]);

  const loadAllMessages = () => {
    // if (!messagesLoadRef.current) return;
    const addNewMessage = (snapshot) => {
      newMessage(mappingMessages(snapshot.val()));
      scrollToBottom();
    };
    // alert(chat?.length - 1)
    const unsubscribe = getNewMessages(
      serviceId,
      chatPhaseSelected.key,
      addNewMessage,
      0
    );

    return unsubscribe

  }

  // useEffect(() => {
  //   const unsubscribe = loadAllMessages();
  //   return () => {
  //     unsubscribe?.();
  //   };
  // }, [
  //   serviceId,
  //   chatPhaseSelected,
  //   // newMessage,
  //   // messagesLoadRef,
  //   mappingMessages,
  //   scrollToBottom,
  // ]);

  const loadMoreMessages = useCallback(async () => {
    if (isLoading || noMoreMessages) return;

    setIsLoading(true);
    try {
      const lastMessage = chat?.[0];
      const messages = await getInitialMessages(
        serviceId,
        chatPhaseSelected.key,
        30,
        lastMessage?.position
      );
      if (messages.length === 0) {
        setNoMoreMessages(true);
      } else {
        addMoreMessages(mappingMessages(messages));
        scrollToPosition(lastMessage.position + 20);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    isLoading,
    noMoreMessages,
    serviceId,
    chatPhaseSelected,
    addMoreMessages,
    mappingMessages,
    scrollToPosition,
    chat,
  ]);

  return {
    chatBodyRef,
    loadMoreMessages,
    isLoading,
    loadAllMessages,
  };
}
