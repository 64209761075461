import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import LOGO_WHITE_TEXT_ICON_SVG from "../../assets/icons/svg/logo-white-text.svg";
import { convertSnapshotToList } from "../../callbacks/list";
import { UID_AUTH } from "../../persistent-managment/key.persistent";
import {
  getValueByKey,
  setObjectByKey,
} from "../../persistent-managment/managment.persistent";
import {
  changePropertiesUser,
  changeServiceProviderCoverage,
} from "../../reducers/app.reducer";
import { login } from "../../reducers/auth.reducer";
import { changeUpdateFormServiceProvider } from "../../reducers/form.service.provider";
import { changeUser } from "../../reducers/form.user.reducer";
import {
  updateServiceListInProgress,
  updateServicesList,
  updateServicesListInit,
} from "../../reducers/servicesList.reducer";
import {
  AUTH_PATH,
  BASE_MENU_PATH,
  BASE_USER_TYPE_PATH,
  PS_MENU_PATH,
  REGISTER_TYPE,
} from "../../router/routes";
import { SingOutUser } from "../../services-controller/auth";
import {
  getAllServicesInProgress,
  getAllServicesInit,
} from "../../services-controller/database";
import {
  allServicesAcceptByServiceProvider,
  serviceProviderById,
  userById,
  userPropertiesById,
} from "../../services-controller/http.cleinte.controller";
import { onAuthStateChanged } from "../../services-firebase/auth";
import "./loadingScreen.css";

import { useDispatch, useSelector } from "react-redux";
const LABEL_IN_PROGRESS = "En curso";
const LABEL_DONE = "Pasados";

export const LoadingScreen = () => {
  const logoElement = useMemo(() => {
    return (
      <img
        src={LOGO_WHITE_TEXT_ICON_SVG}
        className="loading-logo"
        alt={"LOGO_WHITE_TEXT_ICON_SVG"}
      />
    );
  }, []);

  return <div className="loading-screen">{logoElement}</div>;
};

const LoadingScreenController = ({ history }) => {
  const servicesListInit = useSelector(
    (state) => state.services_list.servicesListInit
  );
  const servicesListInProgress = useSelector(
    (state) => state.services_list.servicesListInProgress
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const servicesListUpdated = constructorList(
      servicesListInit,
      servicesListInProgress
    );
    dispatch(updateServicesList(servicesListUpdated));
  }, [servicesListInit, servicesListInProgress, dispatch]);

  //general

  const callbackPushAuth = () => history.push(AUTH_PATH);

  const callbackPushHomeCustomer = () => {
    history.push(BASE_MENU_PATH + "new-home");
  };

  const callbackPushHomeServiceProvider = () => history.push(PS_MENU_PATH);

  const getAllProperties = async (id) => userPropertiesById(id);
  const updateList = (snapshot, setList) => {
    if (typeof snapshot === "object") {
      const auxList = convertSnapshotToList(snapshot.val());
      setList(auxList);
    }
  };

  const constructorList = (servicesListInit, servicesListInProgress) => {
    const servicesListUpdated = [
      [
        LABEL_IN_PROGRESS,
        [
          ...servicesListInit,
          ...servicesListInProgress.filter(
            (service) =>
              service.state.stage === 0 ||
              service.state.stage === 1 ||
              service.state.stage === 2 ||
              service.state.stage === 4 ||
              service.state.stage === 5 ||
              service.state.stage === 7 ||
              service.state.stage === 8 ||
              service.state.stage === 9 ||
              service.state.stage === 10 ||
              service.state.stage === 11 ||
              service.state.stage === 12
          ),
        ],
      ],
      [
        LABEL_DONE,
        [
          ...servicesListInProgress.filter(
            (service) =>
              service.state.stage === 3 ||
              service.state.stage === 6 ||
              service.state.stage === 13
          ),
        ],
      ],
    ];
    return servicesListUpdated;
  };

  const persistentUserCustomer = async (user) => {
    const uid = getValueByKey(UID_AUTH);
    const res = await getAllProperties(uid);

    const dispatchingServicesListInit = (lis) => {
      dispatch(updateServicesListInit(lis));
    };
    const dispatchingServicesListInProgress = (lis) => {
      dispatch(updateServiceListInProgress(lis));
    };

    // Actualizar ambas listas de servicios
    const [initServicesSnapshot, progressServicesSnapshot] = await Promise.all([
      getAllServicesInit((snapshot) =>
        updateList(snapshot, dispatchingServicesListInit)
      ),
      getAllServicesInProgress((snapshot) =>
        updateList(snapshot, dispatchingServicesListInProgress)
      ),
    ]);

    // Función para actualizar la lista de servicios
    const updateLists = () => {
      const servicesListUpdated = constructorList(
        servicesListInit,
        servicesListInProgress
      );
      dispatch(updateServicesList(servicesListUpdated));
    };

    // Actualizar la lista de servicios después de que se completen ambas promesas
    if (initServicesSnapshot && progressServicesSnapshot) {
      updateLists();
    }

    const { object } = res;
    user.id = uid;
    dispatch(changeUser(user));
    dispatch(login(user));
    dispatch(changePropertiesUser(object));

    callbackPushHomeCustomer();
  };

  const persistentUserServiceProvider = async (object, userId) => {
    const { user, coverage } = object;
    user.id = userId;
    const services = await allServicesAcceptByServiceProvider();
    dispatch(updateServicesList(services));
    dispatch(changeUpdateFormServiceProvider(user));
    dispatch(login(user));
    setObjectByKey("user", user);

    dispatch(changeServiceProviderCoverage(coverage));

    callbackPushHomeServiceProvider();
  };

  const getUserById = async (user) => {
    if (user) {

      const resCustomer = await userById(user?.uid);

      const resServiceProvider = await serviceProviderById(user?.uid);
      if (
        Object.keys(resCustomer.object).length === 0 &&
        resServiceProvider === ""
      ) {
        await SingOutUser();
        history.push(`${BASE_USER_TYPE_PATH}${REGISTER_TYPE}`);
      } else if (
        resCustomer.code === 200 &&
        resServiceProvider === "" &&
        !resCustomer.object.is_blocked
      ) {
        persistentUserCustomer(resCustomer.object, user.uid);
      } else if (
        // resCustomer.code == 200 &&
        resServiceProvider !== "" &&
        !resServiceProvider.user.is_blocked
      ) {
        persistentUserServiceProvider(resServiceProvider, user.uid);
      } else {
        await SingOutUser();
        callbackPushAuth();
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged((user) => {
      if (user) getUserById(user);
      else callbackPushAuth();
    });
    return () => {
      unsubscribe();
    };
  }, [callbackPushAuth]);

  return <LoadingScreen />;
};

export default withRouter(LoadingScreenController);
