import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import useReduxState from 'custom-hooks/useReduxState';
import { setActivitiesSectionActive, setChatPhaseSelected, setMediaSectionActive } from 'reducers/bitacle.reducer';
import "./Header.css";
import BitacleSearchBar from '../searchBar/BitacleSearchBar';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

export default function Header() {
  const [{ chatPhaseSelected }, dispatch] = useReduxState({ setMediaSectionActive, setChatPhaseSelected, setActivitiesSectionActive }, "bitacle")
  const btnMoreOptions = [

    {
      key: "1",
      label: (
        <Button
          type="text"
          onClick={() => {
            dispatch.setMediaSectionActive(true)
          }}
        >
          Lista de medios
        </Button>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Button
          type="text"
          onClick={() => {
            dispatch.setActivitiesSectionActive(true)
          }}
        >
          Lista de actividades
        </Button>
      ),
    },

  ]
  const history = useHistory()
  const serviceId = useParams().id
  const auth = useSelector((state) => state.auth)

  const activeSummarySection = () => {
    dispatch.setMediaSectionActive(true)
    dispatch.setActivitiesSectionActive(true)
  }

  return (
    <header className='bitacle-header'>

      <Button onClick={() => {
        dispatch.setChatPhaseSelected({ name: "", key: "" })
        //if is client
        if (auth.userType === "client") {
          history.push("/menu/home")
        } else {
          history.push("/services/bitacle/" + serviceId)
        }
      }} type="primary" shape="circle" icon={< ArrowLeftOutlined />} size={"large"} />

      <section className='header-info' >
        <h1
          onClick={activeSummarySection}
          className="info-title-phase" 
          data-phase={chatPhaseSelected.key}
        >
          Chat de {chatPhaseSelected.name}
        </h1>

        {/* <BitacleSearchBar /> */}
        <Dropdown
          menu={{
            items: btnMoreOptions,
          }}
          rootClassName='dropdown-more'
        >
          <Button
            className="header-btn-more"
            icon={<MoreOutlined />}
            type="text"
            shape="circle"
          >
          </Button>
        </Dropdown>

      </section>
    </header>
  )
}
